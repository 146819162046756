(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview/assets/javascripts/coupon-overview.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview/assets/javascripts/coupon-overview.js');
"use strict";

const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useSelector
} = ReactRedux;
const {
  clx
} = svs.components.lbUtils;
const {
  selectPrice,
  selectSystemType,
  selectRSystem,
  selectUSystem
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  SYSTEM_REDUCERA_FRITT,
  SYSTEM_RSYS,
  SYSTEM_USYS
} = svs.components.tipsen.engine.constants.systems;
const {
  getSystemLabelFromNumber
} = svs.components.sport.systems;
const {
  CouponOverviewHeader,
  useOverviewFooter
} = svs.components.tipsen.couponOverviewParts;
const {
  Coupon
} = svs.components.tipsen.couponComponents;
const {
  useComponentFromRegistry
} = svs.components.tipsen.componentRegistry;
const {
  ShareCoupon
} = svs.components.tipsen.shareCoupon;
const CouponOverview = _ref => {
  var _svs$core$config$data, _svs$core$config$data2;
  let {
    isCompact = false
  } = _ref;
  const couponId = useCouponId();
  const price = useSelector(state => selectPrice(state, couponId));
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const rSystem = useSelector(state => selectRSystem(state, couponId));
  const uSystem = useSelector(state => selectUSystem(state, couponId));
  const systemNum = {
    [SYSTEM_RSYS]: rSystem,
    [SYSTEM_USYS]: uSystem
  }[systemType];
  let systemLabel = 'Standard';
  if (systemNum) {
    systemLabel = getSystemLabelFromNumber(systemNum);
  } else if (systemType === SYSTEM_REDUCERA_FRITT) {
    systemLabel = 'Reducera fritt';
  }

  const CouponOverviewSelector = useComponentFromRegistry('coupon-overview-selector');
  const OverviewFooter = useOverviewFooter(systemType);
  const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
  const showShareCoupon = systemType !== SYSTEM_REDUCERA_FRITT && !isSelfService && !isCompact;
  const isReduceFree = systemType === SYSTEM_REDUCERA_FRITT;
  const blockName = 'pg_coupon_overview';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--compact")]: isCompact,
    ["".concat(blockName, "--reduce-free")]: isReduceFree
  });
  return React.createElement("div", {
    className: classNames
  }, !isCompact && React.createElement(CouponOverviewHeader, {
    systemType: systemType
  }), React.createElement(Coupon, null, React.createElement(CouponOverviewSelector, {
    isCompact: isCompact
  })), !isCompact && React.createElement(OverviewFooter, {
    price: price,
    system: systemLabel
  }), showShareCoupon && React.createElement("div", {
    className: "margin-xs-h-2"
  }, React.createElement(ShareCoupon, {
    className: "margin-top-1"
  })));
};
setGlobal('svs.components.tipsen.couponOverview.CouponOverview', CouponOverview);

 })(window);